import React, { FC } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppState } from 'src/Context';
import { thousandSeparator } from 'src/utils';
// import { DEFAULT_IMAGE } from 'src/constants';
import { analytics } from 'src/utils';

import Shares from './Shares';

import css from './PersonModal.module.scss';
import images from 'src/static/assets';

interface Props {
  className?: string;
}

const countTemplate = 'יחד הנפנו יותר מ-{num} סרטים צהובים';

const PersonModal: FC<Props> = ({ className = '' }) => {
  const { selectedPerson, isPersonModalVisible, candles } = useAppState();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (selectedPerson && isPersonModalVisible) {
      analytics.gtag.event('שתפו שכל העולם יידע', {
        CUSTOM_PARAMETER: `${selectedPerson.b} ${selectedPerson.c}`,
      });
    }
  }, [selectedPerson, isPersonModalVisible]);

  const onCloseBtnClick = () => {
    dispatch({ type: 'SET_IS_PERSON_MODAL_VISIBLE', payload: false });
  };

  const onInstagramBtnClick = () => {
    dispatch({ type: 'SET_IS_PERSON_MODAL_VISIBLE', payload: false });
    dispatch({ type: 'SET_IS_INSTAGRAM_MODAL_VISIBLE', payload: true });
    analytics.gtag.event('כפתור שיתוף', {
      CUSTOM_PARAMETER: `${selectedPerson.b} ${selectedPerson.c}, אינסטגרם`,
    });
  };

  return (
    <div className={cn(css.personModal, className, !isPersonModalVisible && css.hidden)}>
      <div className={css.bg} onClick={onCloseBtnClick} />
      <div className={css.modalContent}>
        <button className={css['close-btn']} onClick={onCloseBtnClick}>
          <span />
          <span />
        </button>

        <div className={css.photo}>
          {selectedPerson?.j && (
            <img src={selectedPerson?.j as string} alt={`${selectedPerson?.a} ${selectedPerson?.b}`} />
          )}
        </div>

        <div className={css.title}>
          {selectedPerson?.a ? `${selectedPerson?.a} ` : ''}
          {selectedPerson?.b} {selectedPerson?.c}
          {selectedPerson?.d && (selectedPerson?.b || selectedPerson?.c) && `, `} {selectedPerson?.d}
        </div>
        <div className={css.subtitle}> {selectedPerson?.l || ''}</div>
        <div className={css.countInfo}>{countTemplate.replace('{num}', `${thousandSeparator(candles.amount)}`)}</div>
        <Shares person={selectedPerson} />
        <div className={css.instagramPopupBtn} onClick={onInstagramBtnClick}>
          <div className={css.imageWrapper}>
            <img src={images.instagramYellow} alt="instagram" />
          </div>
          <div className={css.text}>
            <p>צלמו מסך לסטורי</p>
            <p>כדי שכל העולם יידע &gt;&gt;</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonModal;
