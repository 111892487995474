import { ApiResponse } from '@api/types';
import { API_HOST } from '@api/constants/routes';
import { ROUTE as UPDATE_CANDLES_AMOUNT, Result as UpdateCandlesAmountResult } from '@api/routes/candles/post/params';

export const getPeopleData = async (): Promise<any> => {
  try {
    const CONFIG_URL =
      process.env.ENV === 'production'
        ? `https://mako-bring-them-home.cdn-il.com/website%2Fdata.json?v=${5}`
        : `https://storage.googleapis.com/${process.env.PROJECT_ID}.appspot.com/website%2Fdata.json?v=${Date.now()}`;

    const response = await fetch(CONFIG_URL, { method: 'GET' });
    const result = await response.json();

    return result;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const updateCandlesAmount = async (): Promise<ApiResponse<UpdateCandlesAmountResult>> => {
  const response = await fetch(`${API_HOST}${UPDATE_CANDLES_AMOUNT}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const result = await response.json();

  return result;
};
