import React, { FC } from 'react';
import cn from 'classnames';

import images from 'src/static/assets';
import { PersonInfo } from 'src/Context';
import { analytics } from 'src/utils';
import { share, ShareType } from 'src/utils/share';

import css from './Shares.module.scss';

const HE_SOCIALS = {
  facebook: 'פייסבוק',
  whatsapp: 'ווטסאפ',
  twitter: 'טוויטר אקס',
};

interface Props {
  className?: string;
  person: PersonInfo;
}

const Shares: FC<Props> = ({ className = '', person }) => {
  const onShareBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { type } = e.currentTarget.dataset;

    analytics.gtag.event('כפתור שיתוף', {
      CUSTOM_PARAMETER: `${person.b} ${person.c}, ${HE_SOCIALS[type as ShareType]}`,
    });
    share(type as ShareType, person);
  };

  return (
    <div className={cn(css.shares, className)}>
      <button data-type="whatsapp" className={css.shareBtn} onClick={onShareBtnClick}>
        <img src={images.whatsapp} alt="whatsapp share" />
      </button>
      <button data-type="twitter" className={css.shareBtn} onClick={onShareBtnClick}>
        <img src={images.twitter} alt="twitter share" />
      </button>

      <button data-type="facebook" className={css.shareBtn} onClick={onShareBtnClick}>
        <img src={images.facebook} alt="facebook share" />
      </button>
    </div>
  );
};

export default Shares;
