import React from 'react';
import cn from 'classnames';

import images from 'src/static/assets';
import { analytics } from 'src/utils';

import css from './Socials.module.scss';

type Props = {
  className?: string;
};

enum SHARE {
  FACEBOOK = 'Facebook',
  WHATSAPP = 'Whatsapp',
  TWITTER = 'Twitter',
}

const Socials = (props: Props) => {
  const { className = '' } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const onToggleBtnClick = () => {
    setIsOpen(!isOpen);
  };

  const onSocialBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id as SHARE;
    const domain = window.location.href;
    const encode = encodeURIComponent;
    const titleAndDescription = `שתפו שכל העולם יידע: חייהם של יותר מ-200 ילדים, הורים, קשישים וצעירים השתנו בשבת השחורה ב-7/10. אלו השמות, הפנים והסיפורים מאחורי החטופים בעזה`;

    switch (id) {
      case SHARE.FACEBOOK: {
        const FACEBOOK_URL = 'https://www.facebook.com/sharer.php?u=';

        window.open(`${FACEBOOK_URL}${domain}`, 'sharer', 'width=555,height=600');
        break;
      }

      case SHARE.WHATSAPP: {
        const text = `${titleAndDescription}\n${domain}`;
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const waShareLink = `https://wa.me/?text=${encode(`${text}`)}`;

        if (!isMobile) {
          window.open(waShareLink, '_blank');
        } else {
          window.location.href = waShareLink;
        }
        break;
      }

      case SHARE.TWITTER: {
        const twURL = 'https://twitter.com/intent/tweet?url=';
        const twText = `${titleAndDescription}`;
        const finalURL = `${twURL}${domain}&text=${encode(`${twText}`)}`;

        const shareWindow = window.open(finalURL, 'ShareOnTwitter', 'width=555,height=600');
        if (shareWindow) shareWindow.opener = null;
        break;
      }

      default:
        break;
    }

    analytics.gtag.event('Share activity', { CUSTOM_PARAMETER: id });
  };

  React.useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleOutsideClick = (e: MouseEvent) => {
      if (!container.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className={cn(css.socials, isOpen && css.open, className)} ref={containerRef}>
      <button className={css.toggleBtn} type="button" onClick={onToggleBtnClick}>
        <img src={images.shareToggle} />
      </button>
      <div className={css.btns}>
        <button data-id={SHARE.WHATSAPP} type="button" onClick={onSocialBtnClick}>
          <img src={images.whatsapp} alt="whatsapp" />
        </button>
        <button data-id={SHARE.TWITTER} type="button" onClick={onSocialBtnClick}>
          <img src={images.twitter} alt="mail" />
        </button>
        <button data-id={SHARE.FACEBOOK} type="button" onClick={onSocialBtnClick}>
          <img src={images.facebook} alt="facebook" />
        </button>
      </div>
    </div>
  );
};

export default Socials;
