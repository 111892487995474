import React from 'react';
import ReactDOMClient, { createRoot } from 'react-dom/client';

import 'src/styles/index.scss';
import { App } from 'src/App';

const container = document.getElementById('root');
const Wrap = <App />;

if (container) {
  if (process.env.CLIENT_FORM_SSR) {
    ReactDOMClient.hydrateRoot(container, Wrap);
  } else {
    createRoot(container).render(Wrap);
  }
} else {
  throw new Error('No #root element in DOM');
}
