import React from 'react';
import cn from 'classnames';
import { useResponsive } from 'src/hooks/responsive';
import { DEFAULT_IMAGE } from 'src/constants';
import images from 'src/static/assets';
import css from './GridSkeleton.module.scss';

const GridSkeleton = () => {
  const [isMobile] = useResponsive('MOBILE');
  return (
    <div className={css.skeleton}>
      {Array.from({ length: 4 }).map((_, index) => {
        return (
          <div className={cn(css.item)} key={index}>
            <div className={css.photo}>
              <img src={DEFAULT_IMAGE} />
            </div>
            <div className={css.infoWrap}>
              <div className={css.info}>
                <div className={css.top}>
                  <div className={css.texts}>
                    <div className={css.name}>נעומי בייקר</div>
                    <div className={css.subName}>בת 23, אשדוד</div>
                    <div className={css.description}>בת 23, אשדוד</div>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" className={css.button}>
              <img src={images.ribbonIcon} alt="candle icon" />
              <span>להדלקת נר זיכרון</span>
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default GridSkeleton;
