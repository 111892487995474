import React from 'react';
import cn from 'classnames';
import { thousandSeparator } from 'src/utils';

import { useAppDispatch, useAppState } from 'src/Context';
import images from 'src/static/assets';

import css from './InstagramPopup.module.scss';

const topText = 'צלמו מסך לסטורי כדי שכל העולם יידע >>';
const countTemplate = 'יחד הנפנו יותר מ-{num} <br/> סרטים צהובים';

const InstagramPopup = () => {
  const { selectedPerson, isInstagramModalVisible, candles } = useAppState();
  const dispatch = useAppDispatch();
  const onCloseBtnClick = () => {
    dispatch({ type: 'SET_IS_INSTAGRAM_MODAL_VISIBLE', payload: false });
  };
  return (
    <div className={cn(css.instagramModal, !isInstagramModalVisible && css.hidden)}>
      <button className={css['close-btn']} onClick={onCloseBtnClick}>
        <span />
        <span />
      </button>
      <div className={css.topText}>{topText}</div>
      <div className={css.content}>
        <div className={css.logoWrapper}>
          <img src={images.intro} alt="logo" />
        </div>
        <div className={css.photo}>
          {selectedPerson?.j && (
            <img src={selectedPerson?.j as string} alt={`${selectedPerson?.a} ${selectedPerson?.b}`} />
          )}
        </div>
        <div className={css.title}>
          {selectedPerson?.b} {selectedPerson?.c}
          {selectedPerson?.d && (selectedPerson?.b || selectedPerson?.c) && `, `} {selectedPerson?.d}
        </div>
        <div className={css.subtitle}> {selectedPerson?.l || ''}</div>
        <div
          className={css.countInfo}
          dangerouslySetInnerHTML={{ __html: countTemplate.replace('{num}', `${thousandSeparator(candles.amount)}`) }}
        ></div>
        <div className={css.makoLogo}>
          <img src={images.makoLogo} alt="mako-logo" />
        </div>
      </div>
    </div>
  );
};

export default InstagramPopup;
