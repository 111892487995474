import React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';

import { theme } from 'src/theme';
import { AppContextProvider } from 'src/Context';
import MainPage from 'src/components/pages/Main';

export const App = () => {
  return (
    <ThemeProvider theme={theme()}>
      <CssBaseline />
      <AppContextProvider>
        <MainPage />
      </AppContextProvider>
    </ThemeProvider>
  );
};
