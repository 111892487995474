import React, { createContext, useReducer, useContext } from 'react';
import _ from 'lodash';

import { replaceFields, FIELDS_MAP } from '@api/utils/replace-fields';
import { getPeopleData } from 'src/api';

import { CANDLES_AMOUNT_LS_KEY } from './constants';

export type PersonInfo = Record<keyof typeof FIELDS_MAP, string | number>;

export enum SortOptions {
  AZ = 'az',
  RECENT = 'recent',
  RANDOM = 'random',
}

export type AdvancedFilters = {
  search: string;
  place: string;
  role: string;
  unit: string;
  status: string;
};

interface IState {
  candles: {
    amount: number;
  };
  people: any[];
  title: string;
  selectedPerson: null | PersonInfo;
  isPersonModalVisible: boolean;
  isInstagramModalVisible: boolean;
  sorting: SortOptions;
  currentPage: number;
  filters: AdvancedFilters;
  isFetching: boolean;
  normalizedPeopleData: PersonInfo[];
}

type Action =
  | { type: 'SET_DATA'; payload: Pick<IState, 'candles' | 'people' | 'title'> }
  | { type: 'SET_SELECTED_PERSON'; payload: IState['selectedPerson'] }
  | { type: 'INCREASE_CANDLES_AMOUNT' }
  | { type: 'SET_IS_PERSON_MODAL_VISIBLE'; payload: IState['isPersonModalVisible'] }
  | { type: 'SET_IS_INSTAGRAM_MODAL_VISIBLE'; payload: IState['isInstagramModalVisible'] }
  | { type: 'SET_SORTING'; payload: IState['sorting'] }
  | { type: 'SET_FILTERS'; payload: Partial<IState['filters']> }
  | { type: 'SET_CURRENT_PAGE'; payload: IState['currentPage'] }
  | { type: 'SET_IS_FETCHING'; payload: boolean };

const StateContext = createContext<IState | null>(null);
const DispatchContext = createContext<React.Dispatch<Action> | null>(null);

const initialState: IState = {
  candles: {
    amount: 0,
  },
  title: '',
  isPersonModalVisible: false,
  isInstagramModalVisible: false,
  people: [],
  sorting: SortOptions.RANDOM,
  selectedPerson: null,
  currentPage: 1,
  isFetching: true,
  filters: {
    search: '',
    place: '',
    role: '',
    unit: '',
    status: '0,3',
  },
  normalizedPeopleData: [],
};

function reducer(state: IState, action: Action): IState {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        candles: action.payload.candles,
        title: action.payload.title,
        people: action.payload.people,
        normalizedPeopleData: replaceFields(action.payload.people),
      };

    case 'SET_SELECTED_PERSON':
      return {
        ...state,
        selectedPerson: action.payload,
      };
    case 'SET_IS_PERSON_MODAL_VISIBLE':
      return {
        ...state,
        isPersonModalVisible: action.payload,
      };
    case 'SET_IS_INSTAGRAM_MODAL_VISIBLE':
      return {
        ...state,
        isInstagramModalVisible: action.payload,
      };
    case 'SET_SORTING':
      return {
        ...state,
        sorting: action.payload,
      };
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      };
    case 'SET_IS_FETCHING':
      return {
        ...state,
        isFetching: action.payload,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case 'INCREASE_CANDLES_AMOUNT':
      return {
        ...state,
        candles: {
          ...state.candles,
          amount: state.candles.amount + 1,
        },
      };

    default:
      return state;
  }
}

export function AppContextProvider(props: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  React.useEffect(() => {
    const fetchData = async () => {
      const peopleData = await getPeopleData();

      if (peopleData) {
        const currentCandlesAmount = window.localStorage.getItem(CANDLES_AMOUNT_LS_KEY);

        window.localStorage.setItem(
          CANDLES_AMOUNT_LS_KEY,
          `${Math.max(Number(currentCandlesAmount ?? 0), Number(peopleData.candles.amount))}`,
        );

        dispatch({
          type: 'SET_DATA',
          payload: {
            title: peopleData.title ?? '',
            candles: {
              ...peopleData.candles,
              amount: Math.max(Number(currentCandlesAmount), Number(peopleData.candles.amount)),
            },
            people: _.shuffle(peopleData.rows),
          },
        });
        dispatch({
          type: 'SET_IS_FETCHING',
          payload: false,
        });
      }
    };

    fetchData();
  }, []);

  if (!state.people?.length) {
    // return null;
  }

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{props.children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useAppState must be used within a AppContextProvider');
  }

  return context as { [K in keyof IState]: NonNullable<IState[K]> };
}

export function useAppDispatch() {
  const dispatch = useContext(DispatchContext);

  if (!dispatch) {
    throw new Error('useAppDispatch must be used within a AppContextProvider');
  }

  return dispatch;
}
