import React from 'react';
import cn from 'classnames';
import { useResponsive } from 'src/hooks/responsive';
// import { DEFAULT_IMAGE } from 'src/constants';
import { PersonInfo } from 'src/Context';
import images from 'src/static/assets';

import css from './Persons.module.scss';

const { ribbonIcon } = images;

interface Props {
  showItems: PersonInfo[];
  isAnimation: boolean;
  onPersonCandleBtnClick: (p: PersonInfo) => void;
}

const Persons = ({ showItems, isAnimation, onPersonCandleBtnClick }: Props) => {
  const [isMobile] = useResponsive('MOBILE');
  const clickOnPerson = (p: PersonInfo) => {
    onPersonCandleBtnClick(p);
  };

  const buttonText = (gender: string, status?: string) => {
    switch (gender) {
      case 'גבר':
        return status === '2' || status === '3' ? 'נרצח' : 'חזר הביתה';
      case 'אישה':
        return status === '2' || status === '3' ? 'נרצחה' : 'חזרה הביתה';

      default:
        return 'חזר הביתה';
    }
  };

  const isReturned = (status: string | number) => {
    return Boolean(Number(status));
  };

  const getStatusCSS = (status: string) => {
    switch (status) {
      case '2':
      case '3':
        return css.isMurdered;
      case '1':
        return css.isReturned;
      default:
        return '';
    }
  };

  const renderItems = () => {
    return showItems.map((i, index: number) => {
      return (
        <div
          data-status={i.status}
          data-killed={i.killed || '-'}
          className={cn(css.gridItem, isAnimation && css.animation, getStatusCSS(i.status.toString() || ''))}
          key={`${i.a}${i.b}${i.c}${index}`}
        >
          <div className={css.photo}>{i.j && <img src={`${i.j}`} alt={`${i.b || ''} ${i.c || ''}`.trim()} />}</div>
          <div className={css.infoWrap}>
            <div className={css.info}>
              <div className={css.top}>
                <div className={css.texts}>
                  <div className={css.name}>
                    {i.a ? `${i.a} ` : ''}
                    {i.b} {i.c}
                    {i.d && (i.b || i.c) && `, `} {i.d}
                  </div>
                  <div className={css.description}>{i.l}</div>
                </div>
              </div>
            </div>
          </div>
          {i.killed === '1' ? (
            <button type="button" className={cn(css.button, css.killed)}>
              {i.e === 'גבר' ? 'נהרג' : 'נהרגה'}
            </button>
          ) : (
            <button type="button" className={css.button} onClick={() => clickOnPerson(i)}>
              {(i.status ? isReturned(i.status) : false) ? (
                <span>{buttonText(i?.e as string, i.status.toString())}</span>
              ) : (
                <>
                  <img src={ribbonIcon} alt="candle icon" />
                  <span>שתפו שהעולם יידע</span>
                </>
              )}
            </button>
          )}
        </div>
      );
    });
  };

  return <>{renderItems()}</>;
};

export default Persons;
