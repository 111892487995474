import _ from 'lodash';
import React from 'react';

import { PersonInfo, /* SortOptions, */ useAppState } from 'src/Context';

type Params = { pageSize: number };
export const useFiltered = ({ pageSize }: Params) => {
  const { people, filters, sorting, currentPage } = useAppState();

  const { items, total } = React.useMemo<{ items: PersonInfo[]; total: number }>(() => {
    const filtered = people.filter((item) => {
      const isFilterPassed =
        (filters.place ? item.f === filters.place : true) &&
        (filters.unit ? item.k === filters.unit : true) &&
        (filters.role ? item.g === filters.role : true) &&
        (filters.status !== 'all' ? filters.status.split(',').includes(item.status) : true);

      const fullName = item.b && item.c ? `${item.b} ${item.c}` : undefined;
      let isSearchPassed = true;

      if (fullName && filters.search) {
        isSearchPassed = filters.search.split(' ').every((word) => fullName.toLowerCase().includes(word));
      }

      // search by 'city' also:
      isSearchPassed ||= item.f.toLowerCase().includes(filters.search);

      return isFilterPassed && isSearchPassed;
    });
    // const sorted = sorting === SortOptions.RECENT ? filtered.sort(sortByDate) : _.orderBy(filtered, 'c', 'asc');
    // const sorted = sorting === SortOptions.RANDOM ? _.shuffle(filtered) : filtered.sort(sortByDate);
    const from = (currentPage - 1) * pageSize;

    return { items: filtered.slice(from, pageSize + from), total: filtered.length };
  }, [people, filters, sorting, currentPage]);

  return { items, total };
};

const sortByDate = (a: PersonInfo, b: PersonInfo) => {
  try {
    const [aDate, aMonth, aYear] = (a.date as string).split('/'); // 11/10/2023 -> date/month/year
    const [bDate, bMonth, bYear] = (b.date as string).split('/');

    return new Date(`${bYear}-${bMonth}-${bDate}`).getTime() - new Date(`${aYear}-${aMonth}-${aDate}`).getTime();
  } catch (e) {
    return -1;
  }
};
